import type {
  HeadersFunction,
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from '@remix-run/node'

import { Link, json } from '@remix-run/react'
import { m, useInView } from 'framer-motion'
import {
  ArrowDownIcon,
  ArrowRightIcon,
  LockIcon,
  PlayCircleIcon,
  RocketIcon,
  SparklesIcon,
} from 'lucide-react'
import { useRef, useState } from 'react'
import ReactPlayer from 'react-player/file'

import ConstructionBuddyIcon from '~/components/icons/construction-buddy.svg?react'
import EllipseSvg from '~/components/icons/ellipse.svg?react'
import GridBackgroundSvg from '~/components/icons/grid.svg?react'
import { SignupButton, SiteHeader } from '~/components/layout'
import {
  OG_IMAGE_DEFAULT,
  PROD_SITE_URL,
  SITE_DESCRIPTION,
  SITE_TITLE,
} from '~/constants'
import { CACHE_CONTROL } from '~/utils/http.server'
import { getMeta } from '~/utils/meta/get-meta'
import { getDomainUrl } from '~/utils/misc'
import { FrequentlyAskedQuestions } from './faqs'
import { FeaturesList, features1, features2 } from './features'

export async function loader({ request }: LoaderFunctionArgs) {
  const domain = getDomainUrl(request)
  return json(
    { domain },
    {
      headers: {
        'Cache-Control': CACHE_CONTROL.default,
      },
    },
  )
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  const domain = data?.domain ?? PROD_SITE_URL
  return getMeta({
    title: SITE_TITLE,
    description: SITE_DESCRIPTION,
    siteUrl: domain,
    image: `${domain}/${OG_IMAGE_DEFAULT}`,
  })
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  // Inherit the caching headers from the loader so we don't cache 404s
  return loaderHeaders
}

export const links: LinksFunction = () => {
  return [
    { rel: 'preload', href: '/conkoa.ai-intro-part-1.mp4', as: 'video' },
    {
      rel: 'preload',
      href: '/media/conkoa.ai-video-thumbnail.png',
      as: 'image',
    },
  ]
}

export default function Index() {
  return (
    <div className="max-w-screen dark min-h-screen overflow-hidden bg-background text-foreground">
      <div className="container relative overflow-hidden">
        <HeroSection />
        <KeyPoints />
        <HalfMoon />
        <FeaturesList
          {...features1}
          className="mt-28 md:mt-12 lg:mt-2 xl:-mt-2"
        />
        <FeaturesList {...features2} className="mt-28 xl:mt-40" />
        <SecurityNotice />
        <FrequentlyAskedQuestions />
        <CallToAction />
        <Footer />
      </div>
    </div>
  )
}

function HeroSection() {
  return (
    <div className="mt-3 inline-flex w-full flex-col items-start gap-8 sm:mt-6 md:mt-10 lg:gap-12">
      <LandingHeader />
      <HeroImage />
    </div>
  )
}

function HeroImage() {
  const [playing, setPlaying] = useState(false)
  return (
    <div id="hero-image" className="mx-auto flex flex-col p-0 sm:p-4 md:p-16">
      <div className="relative mx-auto inline-flex w-full flex-col items-start justify-start overflow-hidden rounded-3xl shadow-md sm:w-4/5">
        <ReactPlayer
          height="100%"
          width="100%"
          url="/media/conkoa.ai-intro-part-1.mp4"
          controls
          stopOnUnmount
          playing={playing}
          onClickPreview={() => setPlaying(true)}
          fallback={
            <img
              src="/media/conkoa.ai-video-thumbnail.png"
              alt="video thumbnail"
              loading="eager"
              className="h-full w-full object-cover brightness-90 filter"
            />
          }
          playIcon={
            <div className="absolute rounded-3xl bg-black/60 p-3 md:p-4">
              <PlayCircleIcon
                fill="black"
                className="z-10 h-12 w-12 text-secondary md:h-14 md:w-14"
              />
            </div>
          }
          light={
            <img
              src="/media/conkoa.ai-video-thumbnail.png"
              alt="video thumbnail"
              loading="eager"
              className="h-full w-full object-cover brightness-90 filter"
            />
          }
        />
      </div>
    </div>
  )
}

function LandingHeader() {
  return (
    <div className="flex w-full flex-col items-start gap-8 sm:gap-12">
      <SiteHeader noBorder />
      <header className="mx-auto space-y-10 text-center md:space-y-12">
        <h1 className="font-title text-5xl font-bold text-foreground md:text-6xl lg:text-mega">
          Help Your People Build Things
          <br /> Faster & Better.
        </h1>
        <div className="space-y-5 md:space-y-7">
          <p className="text-lg leading-snug text-muted-foreground md:text-xl">
            <span className="font-medium text-secondary">{SITE_TITLE}</span> is
            the <span className="font-medium text-secondary">AI Co-Pilot</span>{' '}
            Built Exclusively{' '}
            <span className="font-medium text-secondary">
              For Construction Companies.
            </span>
          </p>

          <BetaProgramButton />
          <p className="text-lg leading-snug text-muted-foreground md:text-xl">
            See How It Works
          </p>

          <button
            type="button"
            className="mx-auto flex h-8 w-8 items-center justify-center rounded-xl border border-muted-foreground/50 transition-colors hover:border-secondary/50 focus:border-secondary/50 focus:bg-card focus:outline-none md:h-10 md:w-10"
            onClick={() => {
              const heroImage = document.getElementById('hero-image')
              window.scrollTo({
                top: heroImage?.offsetTop,
                behavior: 'smooth',
              })
            }}
          >
            <ArrowDownIcon className="h-5 w-5 text-muted-foreground md:h-6 md:w-6" />
          </button>
        </div>
      </header>
    </div>
  )
}

function BetaProgramButton() {
  return (
    <a
      href="https://calendly.com/tom-hnar/conkoa-ai-beta-tester-onboarding"
      className="inline-flex flex-col rounded-3xl border-2 border-muted-foreground/50 p-4 transition-colors hover:border-secondary/50 hover:bg-card focus:border-secondary/50 focus:bg-card"
    >
      <div className="flex items-center justify-center gap-3 sm:gap-6">
        <span className="text-center">Join Our Beta Program</span>
        <span className="flex items-center justify-center rounded-xl bg-secondary p-1.5">
          <ArrowRightIcon className="h-6 w-6 text-secondary-foreground" />
        </span>
      </div>
    </a>
  )
}

function KeyPoints() {
  return (
    <div className="z-20 mx-auto mt-28 flex h-fit w-full max-w-lg flex-col items-start justify-center gap-16 overflow-hidden md:max-w-xl md:gap-24 lg:max-w-3xl xl:mt-40 xl:max-w-4xl">
      <p className="z-20 font-title text-3xl font-semibold leading-[120%] text-foreground sm:text-4xl xl:text-5xl">
        You need important answers quickly to better manage your construction
        company.
      </p>
      <p className="z-20 font-title text-3xl font-semibold leading-[120%] text-foreground sm:text-4xl xl:text-5xl">
        <span>
          What if you could access vast construction knowledge and even securely
        </span>{' '}
        <span className="hidden leading-3 text-secondary sm:inline">
          <LockIcon className="inline-block h-10 w-10 lg:h-12 lg:w-12" />{' '}
        </span>
        <span>add your own company data for more powerful</span>{' '}
        <span className="hidden text-secondary sm:inline">
          <RocketIcon className="inline h-10 w-10 lg:h-12 lg:w-12" />
        </span>{' '}
        insights?
      </p>
      <p className="z-20 font-title text-3xl font-semibold leading-[120%] text-foreground sm:text-4xl xl:text-5xl">
        <span>
          That's <span className="text-secondary">{SITE_TITLE}</span>
        </span>{' '}
        <span className="hidden text-secondary sm:inline">
          <SparklesIcon className="inline h-10 w-10 lg:h-12 lg:w-12" />
        </span>
      </p>
      <div className="z-20 self-center">
        <SignupButton />
      </div>
    </div>
  )
}

function HalfMoon() {
  const divRef = useRef<HTMLDivElement>(null)
  const inView = useInView(divRef, {
    once: true,
    amount: 0.3,
  })

  return (
    <div className="relative z-0 mt-4 flex w-full items-center transition-all md:-translate-y-1/4 lg:-mb-20 xl:-mt-12">
      <EllipseSvg className="absolute left-1/2 top-0 z-10 aspect-video w-full -translate-x-1/2 -translate-y-1/3 sm:max-h-80 md:max-h-96 md:-translate-y-10 lg:max-h-[45vw] lg:-translate-y-6 xl:-translate-y-10 [&>path]:fill-background" />

      <m.div
        ref={divRef}
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 0.9 }}
        transition={{ duration: 0.5 }}
        className="aspect-square w-full bg-[radial-gradient(50%_50%_at_50%_50%,_var(--tw-gradient-stops))] from-secondary from-50% to-background blur-xl filter sm:min-h-0 md:max-w-7xl lg:max-h-[1240px] lg:max-w-[1360px]"
      />

      <div className="absolute left-1/2 top-1/2 z-10 w-full max-w-xs -translate-x-1/2 -translate-y-1/3 px-4 text-center sm:max-w-md sm:-translate-y-0 md:-translate-y-0 lg:max-w-2xl xl:translate-y-[100%]">
        <p className="text-center font-title text-2xl font-bold text-foreground dark:text-card sm:text-3xl md:text-4xl lg:text-5xl">
          {SITE_TITLE} is the perfect AI co-pilot for any construction company.
        </p>
      </div>
    </div>
  )
}

function SecurityNotice() {
  return (
    <div className="relative mt-28 xl:mt-40">
      <div className="relative z-10 flex flex-col items-center justify-center gap-3 self-stretch rounded-3xl bg-gradient-to-tr from-[#00364c] from-0% via-primary via-50% to-primary px-10 py-20 text-primary-foreground sm:px-12 sm:py-24">
        <div className="flex w-full max-w-6xl flex-col items-center gap-12">
          <LockIcon className="h-16 w-16" />
          <h2 className="z-20 text-center font-title text-4xl font-bold sm:text-5xl md:text-6xl lg:text-7xl">
            Your data Belongs to you. <br />
            <span className="font-bold">{SITE_TITLE}</span> will keep it safe.
          </h2>
          <p className="z-20 max-w-md text-center font-sans text-lg font-medium md:text-xl">
            We never use your data to train our software and we'll never share
            it with anyone.
          </p>
        </div>
      </div>
      <GridBackgroundSvg className="absolute inset-0 z-10 h-full w-full rounded-3xl opacity-15" />
    </div>
  )
}

function Footer() {
  return (
    <footer className="relative mb-8 mt-28 flex flex-col items-center justify-center gap-4 self-stretch xl:mt-40">
      <div className="">
        <ConstructionBuddyIcon className="h-14 w-14" />
      </div>
      <div className="z-20 flex items-center gap-x-4">
        <p className="text-sm text-muted-foreground">
          {new Date().getFullYear()} &copy; {SITE_TITLE}.
        </p>
        <Link
          to="/privacy-policy"
          className="text-sm text-muted-foreground underline"
        >
          Privacy Policy
        </Link>
        <Link
          to="/terms-of-service"
          className="text-sm text-muted-foreground underline"
        >
          Terms of Service
        </Link>
      </div>
      <div className="absolute top-1/2 h-96 w-full bg-[radial-gradient(50%_50%_at_50%_50%,_var(--tw-gradient-stops))] from-muted-foreground/15 from-50% to-background blur-2xl filter" />
    </footer>
  )
}

function CallToAction() {
  return (
    <div className="mt-28 flex flex-col items-center gap-12 xl:mt-40">
      <h3 className="max-w-6xl text-center font-title text-5xl font-semibold sm:text-6xl lg:text-7xl">
        Ready to hit turbo boost on your construction company?
      </h3>
      <p className="text-center text-lg text-muted-foreground md:text-xl">
        Start your journey with us and experience
        <br /> the difference{' '}
        <span className="font-medium text-secondary">{SITE_TITLE}</span> can
        make.
      </p>
      <SignupButton />
    </div>
  )
}
